import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const VisionAndValues = () => {
    return (
        <Layout>
            <Seo title="Vision & Values" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Vision & Values</h1>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <div className="card-body h-100 bg-dark " style={{ padding: '16px' }}>
                            <blockquote className="blockquote text-light mb-0">
                                <p>"Our goal is to be the kind of business organization in which people can place their trust."</p>
                                <footer className="blockquote-footer text-light text-end">James Richardson, 1857.</footer>
                            </blockquote>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card-body h-100 gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                            <h2>Vision</h2>
                            <p>Success starts with a vision</p>
                            <p>
                                Our Goal is to be an innovative, can-do organization, that constantly evolves by exploring new ideas,
                                new methods and seeking new opportunities with Trust & Integrity. We remain resolute in our commitment
                                to conducting business in an honourable, responsible and respectful manner reflective of our corporate
                                vision and values.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card-body h-100 gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                            <h2>Mission</h2>
                            <p>
                                To build a Sustainable and Profitable business that generates long-term value for all our stakeholders;
                                external and internal. Provide a high-quality service in the transportation and management of bulk
                                commodities in a reliable and cost-effective manner that creates and enhances value of the logistic
                                supply chain.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VisionAndValues
